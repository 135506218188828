<template>
	<div class="container-fluid conditions_container">
		<aq-confirmation-modal
			ref="deleteParentConditionModal"
			name="delete-parent-condition-modal"
			title="Confirm Delete Parent Condition"
			description="Removing this condition will break condition parenting."
			question="Do you wish to proceed?"
			yes-label="Proceed"
			no-label="Cancel"
			data-qa="claim-conditions_modal_delete-parent-condition"
		/>
		<div
			class="add-condition_container"
			v-if="multicondition && !isReadOnlyMode"
		>
			<div
				class="add_condtiton_button"
				@click="onAddCondition"
			>
				<i class="fa fa-plus-circle text-primary mr-10" />
				<span>Add Condition</span>
			</div>
		</div>
		<div
			class="row my-20"
			v-for="(condition, index) in conditions"
			:key="index"
		>
			<div :class="gridSizes[0]">
				<aq-highlight-border-with-icon
					:highlight-color="getConditionValidationMessage(condition) ? getConditionValidationSeverity(condition) : null"
					:icon="getConditionValidationMessage(condition) && getConditionValidationIcon(condition)"
					:icon-circle="isEligibleCondition(condition)"
					class="condition"
				>
					<aq-loss-types
						v-if="isOtherLoss"
						:is-valid="!validator.$each.$iter[index].selectedAilment.$error"
						v-model="condition.selectedAilment"
						:disabled="isReadOnlyMode"
						@input="onAilmentChanged(index, $event)"
					/>
					<aq-ailments
						v-else
						data-qa="enterBasic_input_ailment"
						v-model="condition.selectedAilment"
						:is-valid="!validator.$each.$iter[index].selectedAilment.$error"
						:disabled="isReadOnlyMode"
						@input="onAilmentChanged(index, $event)"
					/>
					<template slot="tooltipContent">
						<div>
							<div class="text-underline text-bold">
								{{ getTooltipHeader(condition) }}
							</div>
							<div>{{ getConditionValidationMessage(condition) }}</div>
							<div v-if="hasPossibleSign(condition) && condition.preExConditionValidationResult.firstPossibleSignDate">
								<br><div class="text-underline text-bold">
									Date Notified:
								</div>
								<div>{{ condition.preExConditionValidationResult.firstPossibleSignDate | longDate }}</div>
							</div>
						</div>
					</template>
				</aq-highlight-border-with-icon>
				<aq-form-input-error
					class="error mt-4"
					:error-messages="$options.errorMessages"
					:validator="validator.$each.$iter[index]"
					property="selectedAilment"
				/>
			</div>
			<div
				v-if="!isOtherLoss"
				:class="gridSizes[1]"
			>
				<aq-body-parts
					data-qa="enterBasic_input_bodyPart"
					v-model="condition.selectedBodyPart"
					@input="onBodyPartChanged(index, $event)"
					:is-valid="!validator.$each.$iter[index].selectedBodyPart.$error"
					:disabled="isReadOnlyMode"
				/>
				<aq-form-input-error
					class="error mt-4"
					:error-messages="$options.errorMessages"
					:validator="validator.$each.$iter[index]"
					property="selectedBodyPart"
				/>
			</div>
			<div :class="gridSizes[2]">
				<div v-if="condition.selectedAilment">
					<div v-if="getConditionTypes(condition.selectedAilment).length > 1">
						<aq-select
							data-qa="enterBasic_input_conditionType"
							:options="getConditionTypes(condition.selectedAilment)"
							label="Condition Type"
							option-label="description"
							:is-valid="!validator.$each.$iter[index].selectedConditionType.$error"
							class="w-100"
							v-model="condition.selectedConditionType"
							@input="onConditionTypeChanged(index, $event)"
							:disabled="isReadOnlyMode"
							track-by="id"
							no-result-message="No Condition Types found"
						/>
						<aq-form-input-error
							class="error mt-4"
							:error-messages="$options.errorMessages"
							:validator="validator.$each.$iter[index]"
							property="selectedConditionType"
						/>
					</div>
					<div v-if="getConditionTypes(condition.selectedAilment).length === 1 && condition.selectedConditionType">
						<div class="label text-primary">
							Condition Type
						</div>
						<div class="mt-10">
							{{ condition.selectedConditionType.description }}
						</div>
					</div>
				</div>
			</div>
			<div
				class="d-flex align-items-center justify-content-end"
				:class="gridSizes[3]"
				v-if="multicondition"
			>
				<template v-if="$can.ClaimEditContinuation">
					<button
						v-if="condition.parentConditionId"
						class="btn btn-primary mr-20"
						@click="onUnbindClaim(index)"
						:disabled="isReadOnlyMode"
					>
						New Claim
					</button>
					<button
						v-if="!condition.parentConditionId && !condition.isParent"
						class="btn btn-primary mr-20"
						@click="onContinuationClaim(index)"
						:disabled="isReadOnlyMode"
					>
						Continuation Claim
					</button>
					<div v-if="condition.parentConditionId">
						<i
							class="aqv-info fs-32 text-primary mr-20"
							v-tooltip="getParentConditionDOL(condition)"
						/>
					</div>
				</template>
				<aq-dropdown
					data-qa="claim_conditions_options"
					:options="getActions(condition)"
					:is-read-only-mode="isReadOnlyMode"
					@select="onSelectedAction($event, index)"
					:height="37"
					btn-class="small btn-row task-table_row_action"
					:disabled="isReadOnlyMode"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ClaimService from '@commonServices/claimService';
import PetService from '@commonServices/petService';
import { ValidationMessages } from '@commonServices/utils/constants';
import { toDateOfLossLabel } from '@commonServices/utils/converter';
import { areDatesEqual, fromStringToDate } from '@commonServices/utils/dateUtils';
import WaitPeriod from '@commonServices/models/WaitPeriod';
import PolicyValidationResultEnum from '@commonServices/models/PolicyValidationResultEnum';
import WaitingPeriodValidationResultEnum from '@commonServices/models/WaitingPeriodValidationResultEnum';
import { shortDate } from '@commonServices/utils/filters';
import { ClaimConditionState } from '@commonServices/models/ClaimConditionState';
import { createCondition, getConditionOverrideOption, hasManualRejection, hasOverride, isConditionSystemRejected } from '@commonView/ClaimPage/ClaimEnter/claimUtils';
import AqHighlightBorderWithIcon from '@commonWidgets/AqHighlightBorderWithIcon';
import AqLossTypes from '@commonWidgets/AqLossTypes';
import { AILMENTS_FIRST_CAUSE } from '@clientCommon/constants/ailmentsFirstCause';
import { BODY_PARTS_CODE } from '@clientCommon/constants/bodyPartsCode';

const preExistingMsg = 'Diagnosis is a Pre-Existing Condition';
const preExistingRelatedMsg = 'Diagnosis is a Related Condition';
const eligibleMsg = 'Diagnosis is a Eligible Condition';
const noProductCoverageMsg = 'No Product Coverage';
const noActiveCoverPeriodMsg = 'Policy Warning - No Active Cover Period';

const errorMessages = {
	selectedAilment: {
		required: ValidationMessages.required,
		uniqValue: 'Duplicate',
		preExisting: preExistingMsg,
		preExistingRelated: preExistingRelatedMsg,
	},
	selectedConditionType: {
		required: ValidationMessages.required,
		uniqValue: 'Duplicate',
	},
	selectedBodyPart: {
		required: ValidationMessages.required,
		uniqValue: 'Duplicate',
	},
};

const actions = {
	reject: { name: 'Manual Rejection', value: 'manual-reject', iconClass: 'fa-thumbs-down' },
	cancelReject: { name: 'Remove Manual Rejection', value: 'manual-remove-reject', iconClass: 'fa-thumbs-up' },
	remove: { name: 'Remove', value: 'remove', iconClass: 'fa-times' },
	override: { name: 'Override Rejection', value: 'override-rejection', iconClass: 'fa-check' },
	cancelOverride: { name: 'Cancel Rejection Override', value: 'cancel-override', iconClass: 'fa-eject' },
};

export default {
	name: 'ClaimConditions',
	components: { AqLossTypes, AqHighlightBorderWithIcon },
	props: {
		sharedData: {
			type: Object,
			required: true,
		},
		dateOfLoss: {
			type: Date,
			required: false,
			default: null,
		},
		dateOfLossValid: {
			type: Boolean,
			required: false,
		},
		treatmentStart: {
			type: Date,
			required: false,
			default: null,
		},
		isReadOnlyMode: {
			type: Boolean,
			required: true,
		},
		multicondition: {
			type: Boolean,
			required: true,
		},
		conditions: {
			type: Array,
			required: true,
		},
		validator: {
			type: Object,
			required: true,
		},
		isOtherLoss: {
			type: Boolean,
			required: true,
		},
		labels: {
			type: Object,
			required: true,
		},
	},
	errorMessages,
	mounted () {
		this.checkAllConditions({ activeCoverPeriod: true, productCoverage: true, preExistingCondition: true, waitPeriod: true, predictAmount: true, eligibility: true, checkParentDOL: true });
		this.loadBodyParts();
	},
	watch: {
		dateOfLoss: function (newValue, oldValue) {
			if (!areDatesEqual(newValue, oldValue)) {
				for (let conditionIndex = 0; conditionIndex < this.conditions.length; conditionIndex++) {
					this.resetConditionValidationState(conditionIndex);
				}
				this.checkAllConditions({ activeCoverPeriod: true, productCoverage: true, preExistingCondition: true, waitPeriod: true, eligibility: true });
			}
		},
		treatmentStart: function (newValue, oldValue) {
			if (!areDatesEqual(newValue, oldValue)) {
				for (let conditionIndex = 0; conditionIndex < this.conditions.length; conditionIndex++) {
					this.resetConditionValidationState(conditionIndex);
				}
				this.checkAllConditions({ activeCoverPeriod: true, productCoverage: true, preExistingCondition: true, waitPeriod: true, checkParentDOL: true });
			}
		},
		isOpenConditionsPanel: function (value) {
			if (!value) {
				this.checkAllConditions({ preExistingCondition: true, eligibility: true });
			}
		},
	},
	computed: {
		...mapState(['isOpenConditionsPanel', 'appSettings']),
		...mapState(['bodyParts']),
		gridSizes () {
			return this.multicondition ? ['col-6', 'col-2', 'col-2', this.isOtherLoss ? 'col-4' : 'col-2'] : ['col-8', 'col-2', 'col-2', 'col-0'];
		},
		validationSchema () {
			if (this.appSettings.validationSchema === 'PreEx') {
				return [
					{
						name: 'preExistingCondition',
						checkFunc: this.checkPreExistingCondition,
						skip: () => this.isOtherLoss,
					},
					{
						name: 'activeCoverPeriod',
						checkFunc: this.checkActiveCoverPeriod,
						skip: () => false,
					}, {
						name: 'productCoverage',
						checkFunc: this.checkProductCoverage,
						skip: (condition) => condition.isNoActiveCoverPeriodError,
					}, {
						name: 'waitPeriod',
						checkFunc: this.checkWaitPeriod,
						skip: (condition) => condition.isNoActiveCoverPeriodError || condition.isNoProductCoverageError,
					}];
			}
			return [
				{
					name: 'activeCoverPeriod',
					checkFunc: this.checkActiveCoverPeriod,
					skip: () => false,
				}, {
					name: 'productCoverage',
					checkFunc: this.checkProductCoverage,
					skip: (condition) => condition.isNoActiveCoverPeriodError,
				}, {
					name: 'preExistingCondition',
					checkFunc: this.checkPreExistingCondition,
					skip: (condition) => this.isOtherLoss || condition.isNoActiveCoverPeriodError || condition.isNoProductCoverageError,
				}, {
					name: 'waitPeriod',
					checkFunc: this.checkWaitPeriod,
					skip: (condition) => condition.isNoActiveCoverPeriodError || condition.isNoProductCoverageError,
				}];
		},
	},
	methods: {
		...mapActions(['loadBodyParts']),
		onAilmentChanged (conditionIndex, value) {
			this.updateConditionType(conditionIndex);

			this.resetConditionValidationState(conditionIndex);
			this.validator.$each.$iter[conditionIndex].selectedAilment.$touch();

			if (value) {
				this.checkCondition(conditionIndex, { activeCoverPeriod: true, productCoverage: true, preExistingCondition: true, waitPeriod: true, predictAmount: true, eligibility: true });
			} else {
				this.validator.$each.$iter[conditionIndex].selectedConditionType.$reset();
			}
		},
		onConditionTypeChanged (conditionIndex, value) {
			this.resetConditionValidationState(conditionIndex, { activeCoverPeriod: true, productCoverage: true, preExistingCondition: true, waitPeriod: true, eligibility: true });
			this.validator.$each.$iter[conditionIndex].selectedConditionType.$touch();

			if (value) {
				this.checkCondition(conditionIndex, { activeCoverPeriod: true, productCoverage: true, preExistingCondition: true, waitPeriod: true, predictAmount: true, eligibility: true });
			}
		},
		onBodyPartChanged (conditionIndex, value) {
			this.resetConditionValidationState(conditionIndex, { preExistingCondition: true, eligibility: true });
			this.validator.$each.$iter[conditionIndex].selectedBodyPart.$touch();

			if (value) {
				this.checkCondition(conditionIndex, { preExistingCondition: true, eligibility: true });
			}
		},
		updateConditionType (conditionIndex) {
			const condition = this.conditions[conditionIndex];
			condition.selectedConditionType = null;
			if (condition.selectedAilment) {
				const conditionTypes = this.getConditionTypes(condition.selectedAilment);
				if (conditionTypes.length === 1) {
					condition.selectedConditionType = conditionTypes[0];
				}

				const noneBodyPart = this.bodyParts.find(x => x.code === BODY_PARTS_CODE.NoneBlank || x.code === BODY_PARTS_CODE.NoneNone);
				if (condition.selectedAilment.firstCause === AILMENTS_FIRST_CAUSE.Wellness && !!noneBodyPart) {
					condition.selectedBodyPart = noneBodyPart;
				}
			}
		},
		checkAllConditions (checks) {
			for (let index = 0; index < this.conditions.length; index++) {
				this.checkCondition(index, checks);
			}
		},
		// checks condition based on validation schema
		async checkCondition (conditionIndex, checks) {
			if (this.isReadOnlyMode) return;

			const condition = this.conditions[conditionIndex];

			const validations = this.validationSchema.filter(v => checks[v.name]);

			for (const validator of validations) {
				if (validator.skip(condition)) {
					continue;
				}
				await validator.checkFunc(condition);
			}

			return Promise.all(
				[
					checks.eligibility && this.validateForEligibleCondition,
					checks.predictAmount && this.predictConditionAmount,
				]
					.filter(Boolean)
					.map(validationMethod => validationMethod(condition)),
			);
		},
		checkActiveCoverPeriod (condition) {
			if (!this.dateOfLoss || !this.treatmentStart) return;

			const conditionTypeId = condition.selectedConditionType ? condition.selectedConditionType.id : null;
			return ClaimService.validatePolicy(this.sharedData.petId, this.treatmentStart, this.dateOfLoss, conditionTypeId)
				.then(resValidationCode => {
					const isNoActiveCoverPeriodError = (resValidationCode !== PolicyValidationResultEnum.VALID);
					if (condition.isNoActiveCoverPeriodError !== isNoActiveCoverPeriodError) {
						if (!isNoActiveCoverPeriodError) {
							condition.ignoreNoCoverPeriod = false;
						}
					}
					condition.isNoActiveCoverPeriodError = isNoActiveCoverPeriodError;
					condition.isDOLPriorPetDOBError = (resValidationCode === PolicyValidationResultEnum.INVALID_DOL_PRIOR_PET_DOB);
				})
			;
		},
		checkProductCoverage (condition) {
			if (condition.isNoActiveCoverPeriodError || condition.selectedAilment == null || condition.selectedConditionType == null) return;

			const data = {
				claimId: this.sharedData.claimId,
				dateOfLoss: this.dateOfLoss,
				ailmentId: condition.selectedAilment.id,
				claimConditionTypeId: condition.selectedConditionType.id,
			};
			return ClaimService.checkProductCoverage(data)
				.then(res => {
					const isNoProductCoverageError = (res.validationStatus === WaitingPeriodValidationResultEnum.INVALID);
					if (condition.isNoProductCoverageError !== isNoProductCoverageError) {
						if (!isNoProductCoverageError) {
							condition.ignoreNoProductCoverage = false;
						}
					}
					condition.isNoProductCoverageError = isNoProductCoverageError;
				});
		},
		checkPreExistingCondition (condition) {
			if (condition.selectedAilment == null || condition.selectedBodyPart == null) return;
			return PetService.validateForPreExistingCondition(this.sharedData.claimId, condition.selectedAilment.id, condition.selectedBodyPart.id)
				.then(preExConditionValidationResult => {
					condition.preExConditionValidationResult = preExConditionValidationResult;
					if (!condition.preExConditionValidationResult?.hasRejection) {
						condition.ignorePreExistingCondition = false;
					}
				});
		},
		checkWaitPeriod (condition) {
			if (condition.selectedAilment == null || condition.selectedConditionType == null) return;
			const data = new WaitPeriod(
				this.sharedData.claimId,
				this.dateOfLoss,
				condition.selectedAilment.id,
				condition.selectedConditionType.id,
			);
			return ClaimService.checkWaitPeriod(data)
				.then(res => {
					condition.isWaitingPeriodError = (res.validationStatus === WaitingPeriodValidationResultEnum.INVALID);
					condition.waitingPeriodEndDate = fromStringToDate(res.waitingPeriodEndDate);
					if (!condition.isWaitingPeriodError) {
						condition.ignoreWaitingPeriod = false;
					}
				});
		},
		validateForEligibleCondition (condition) {
			if (condition.selectedAilment == null || condition.isNoActiveCoverPeriodError || condition.isNoProductCoverageError || condition.selectedBodyPart == null) return;

			return PetService.validateForEligibleCondition(this.sharedData.claimId, condition.selectedAilment.id, condition.selectedBodyPart.id)
				.then(eligibleConditionValidationResult => {
					condition.eligibleConditionValidationResult = eligibleConditionValidationResult;
				});
		},
		predictConditionAmount (condition) {
			if (!this.appSettings.machineLearningEnabled || condition.selectedAilment == null || condition.selectedConditionType == null) return;

			const data = {
				claimType: condition.parentConditionId ? 'Continuation' : 'New',
				ailment: condition.selectedAilment.firstCause,
				accidentOrIllness: condition.selectedConditionType.description,
				policySection: '',
				policySubSection: '',
				breed: this.sharedData.petBreed,
				species: this.sharedData.petType,
				yearsOld: this.sharedData.petYearsOld,
				pcArea: this.sharedData.customerPostalCode,
			};
			return ClaimService.getPredictedClaimAmount(data)
				.then(value => {
					condition.predictedConditionAmount = value;
				});
		},
		getConditionTypes (ailment) {
			return ailment.conditionTypes.filter((item) => item.system);
		},
		onAddCondition () {
			this.conditions.push(createCondition());
		},
		getActions (condition) {
			const options = [];
			if (this.isReadOnlyMode) return options;
			if (this.$can.OverrideClaimRejection) {
				if (this.isInSystemRejectionState(condition) && !hasManualRejection(condition)) {
					const overrideName = getConditionOverrideOption(condition);
					options.push({ ...actions.override, name: overrideName });
				}
				if (hasOverride(condition)) {
					options.push(actions.cancelOverride);
				}
			}
			if (this.$can.ManualRejectClaim && condition.selectedAilment && condition.selectedConditionType && (condition.selectedBodyPart || this.isOtherLoss)) {
				options.push(hasManualRejection(condition) ? actions.cancelReject : actions.reject);
			}

			options.push(actions.remove);
			return options;
		},
		isInSystemRejectionState (condition) {
			return isConditionSystemRejected(condition, this.isReadOnlyMode);
		},
		resetConditionValidationState (conditionIndex, checks = { activeCoverPeriod: true, productCoverage: true, preExistingCondition: true, waitPeriod: true, eligibility: true }) {
			const condition = this.conditions[conditionIndex];
			if (checks.activeCoverPeriod) {
				condition.isNoActiveCoverPeriodError = false;
				condition.isDOLPriorPetDOBError = false;
				condition.ignoreNoCoverPeriod = false;
			}
			if (checks.productCoverage) {
				condition.isNoProductCoverageError = false;
				condition.ignoreNoProductCoverage = false;
			}

			if (checks.preExistingCondition) {
				condition.preExConditionValidationResult = null;
				condition.ignorePreExistingCondition = false;
			}

			if (checks.waitPeriod) {
				condition.isWaitingPeriodError = false;
				condition.ignoreWaitingPeriod = false;
				condition.waitingPeriodEndDate = null;
			}
			if (checks.eligibility) {
				condition.eligibleConditionValidationResult = null;
			}
		},
		async onSelectedAction (action, conditionIndex) {
			if (action === actions.remove.value) {
				const condition = this.conditions[conditionIndex];
				if (condition.isParent) {
					const userChoise = await this.$refs.deleteParentConditionModal.show();
					if (userChoise === 'no') {
						return;
					}
				}
				this.conditions.splice(conditionIndex, 1);
				if (this.conditions.length === 0) this.onAddCondition();
			} else {
				this.$emit(action, conditionIndex);
			}
		},
		onContinuationClaim (conditionIndex) {
			this.$emit('continuation-claim', conditionIndex);
		},
		async onUnbindClaim (conditionIndex) {
			this.$emit('unbind-claim', conditionIndex);
		},
		rejectionReasonExists (condition) {
			return condition.isNoActiveCoverPeriodError || condition.isNoProductCoverageError
			|| condition.preExConditionValidationResult?.hasRejection || condition.isWaitingPeriodError
			|| condition.rejectionData.isManuallyRejected || (this.isReadOnlyMode && condition.state !== ClaimConditionState.Proceed);
		},
		hasPossibleSign (condition) {
			return condition.preExConditionValidationResult?.hasPossibleSign;
		},
		getTooltipHeader (condition) {
			if (this.rejectionReasonExists(condition)) {
				return 'Rejection Reason:';
			}

			if (this.isEligibleCondition(condition)) {
				return;
			}

			if (this.hasPossibleSign(condition)) {
				return 'Possible Sign:';
			}
		},
		getConditionValidationIcon (condition) {
			if (!this.isInSystemRejectionState(condition) && this.isEligibleCondition(condition)) {
				return 'aqv-circle-thumbs-up';
			}
			return 'fa-exclamation-triangle';
		},
		getConditionValidationSeverity (condition) {
			if (this.isInSystemRejectionState(condition) || condition.rejectionData.isManuallyRejected || condition.state === ClaimConditionState.RejectedManually) {
				return 'danger';
			}
			if (condition.preExConditionValidationResult?.hasPossibleSign) {
				return 'warning';
			}
			if (this.isEligibleCondition(condition)) {
				return 'info';
			}
			return 'success';
		},
		getConditionValidationMessage (condition) {
			if (this.isReadOnlyMode) {
				if (condition.state === ClaimConditionState.RejectedByNoPolicyCover) { return noActiveCoverPeriodMsg; }
				if (condition.state === ClaimConditionState.RejectedByNoProductCover) { return noProductCoverageMsg; }
				if (condition.state === ClaimConditionState.RejectedWaitingPeriod) { return `${this.getDateOfLossLabel()} is before the Policy Wait Period end date ${shortDate(condition.waitingPeriodEndDate)}.`; }
				if (condition.state === ClaimConditionState.RejectedByPreExistingCondition) { return preExistingMsg; }
				if (condition.state === ClaimConditionState.RejectedByRelatedCondition) { return preExistingRelatedMsg; }
				if (condition.state === ClaimConditionState.RejectedManually) { return condition.rejectionData.rejectionReasonDescription; }
			} else {
				const conditionRejections = [
					{ rejected: condition.rejectionData?.isManuallyRejected || false, ignored: false, message: condition.rejectionData?.rejectionReasonDescription },
					{ rejected: condition.preExConditionValidationResult?.rejectedByPreExistingCondition || false, ignored: condition.ignorePreExistingCondition, message: preExistingMsg },
					{ rejected: condition.preExConditionValidationResult?.rejectedByRelatedCondition || false, ignored: condition.ignorePreExistingCondition, message: preExistingRelatedMsg },
					{ rejected: condition.isNoActiveCoverPeriodError, ignored: condition.ignoreNoCoverPeriod, message: noActiveCoverPeriodMsg },
					{ rejected: condition.isNoProductCoverageError, ignored: condition.ignoreNoProductCoverage, message: noProductCoverageMsg },
					{ rejected: condition.isWaitingPeriodError, ignored: condition.ignoreNoProductCoverage, message: `${this.getDateOfLossLabel()} is before the Policy Wait Period end date ${shortDate(condition.waitingPeriodEndDate)}.` },
				];

				// identify rejection message based on current condition rejection state
				if (conditionRejections.some(c => c.rejected)) {
					const entry = conditionRejections.find(c => c.rejected && !c.ignored) ?? conditionRejections.findLast(c => c.rejected && c.ignored);
					return entry.message;
				}

				if (condition.preExConditionValidationResult?.hasPossibleSign) { return `${condition.selectedAilment.firstCause} - ${condition.selectedBodyPart.description}`; }
			}
			if (this.isEligibleCondition(condition)) { return eligibleMsg; }
			return '';
		},
		getDateOfLossLabel () {
			return toDateOfLossLabel(this.appSettings);
		},
		getParentConditionDOL (condition) {
			if (this.treatmentStart && condition.parentClaimDateOfLoss && this.treatmentStart < fromStringToDate(condition.parentClaimDateOfLoss)) {
				return 'Current condition will become the parent condition';
			}

			return `Parent ${this.getDateOfLossLabel()}: ${shortDate(condition.parentClaimDateOfLoss)}`;
		},
		isEligibleCondition (condition) {
			return condition.eligibleConditionValidationResult?.isEligible
				|| (this.isReadOnlyMode && condition.state === ClaimConditionState.Proceed && condition.eligibleConditionId);
		},
	},
};
</script>

<style lang="scss" scoped>
.conditions_container {
  .add-condition_container {
    display: flex;
    align-items: center;
    background: var(--cardIconBg);
    margin: 0 -20px;
    padding-left: 23px;
    height: 40px;

    .add_condtiton_button {
      text-decoration: underline;
      color: var(--bodyColour);

      &:hover {
        color: $primary;
        cursor: pointer;
      }
    }
  }
}
</style>
