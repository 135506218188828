<template>
	<div
		v-if="claimFormType"
		class="sidebar"
		:class="{ 'pre-auth': isPreAuthorisation, 'other-loss': isOtherLoss }"
	>
		<div class="d-flex flex-column align-items-center gap-2 py-20">
			<div
				v-if="invoiceFiles.length"
				class="action-item"
				@click="openInvoiceFile"
			>
				<FileIconInvoice class="file-icon" />
				<span>Open Invoice</span>
			</div>
			<div
				class="action-item"
				@click="openClaimHistory"
			>
				<FileIconClaim class="file-icon" />
				<span>Claim History</span>
			</div>
		</div>
	</div>
</template>

<script>
import { ClaimFormType } from '@commonServices/models/ClaimFormType';
import { mapState } from 'vuex';
import { FileIconClaim, FileIconInvoice } from '@assets/icons';
import FileService from '@commonServices/fileService';
import { FileCategoryEnum } from '@commonServices/models/FileCategoryEnum';
import { MimeTypes, getFileExtension } from '@commonServices/utils/general';
import { openPetNewWindow } from '@commonServices/utils/routerUtils';

export default {
	name: 'ClaimSidebar',
	props: {
		sharedData: {
			type: Object,
			required: true,
		},
	},
	data () {
		return {
			invoiceFiles: [],
		};
	},
	components: {
		FileIconClaim,
		FileIconInvoice,
	},
	mounted () {
		this.loadInvoices();
	},
	computed: {
		...mapState(['claimFormType', 'isOpenDocPanel']),
		isPreAuthorisation () {
			return this.claimFormType.id === ClaimFormType.PreAuthorisation;
		},
		isOtherLoss () {
			return this.claimFormType.id === ClaimFormType.OtherLosses;
		},
	},
	watch: {
		isOpenDocPanel (isOpen) {
			if (!isOpen && !this.sharedData.isReadOnlyMode) {
				this.loadInvoices();
			}
		},
	},
	methods: {
		openClaimHistory () {
			openPetNewWindow(this.$router, this.sharedData.customerId, this.sharedData.petId);
		},
		openInvoiceFile () {
			this.invoiceFiles.forEach(f => {
				FileService.getTempSrc(f.id, f.docMimeType).then(file => window.open(file, '_blank'));
			});
		},
		loadInvoices () {
			FileService.getClaimFiles(this.sharedData.claimId)
				.then(data => {
					this.invoiceFiles = data.filter(f => [FileCategoryEnum.Invoice, FileCategoryEnum.HospitalInvoice].includes(f.category))
						.map((f) => ({
							...f,
							docMimeType: MimeTypes[getFileExtension(f.fileName)],
						}));
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.sidebar {
  display: flex;
  flex-shrink: 0;
  width: 80px;
  flex-direction: column;
  background: rgba($primary, 0.6);

  &.pre-auth {
    background: var(--bannerBgAlt);
    color: var(--sectionTextColour);
  }

  &.other-loss {
    background: var(--otherLossBg);
    color: var(--sectionTextColour);
  }
}

.file-icon {
  .a {
    fill: transparent;
  }

  .b {
    fill: $body-color;
  }

  &.disabled {
    .b {
      opacity: 0.4;
    }
  }
}

.action-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: $body-color;
  cursor: pointer;
}
</style>
